<template>
  <img alt="logo" src="./assets/lamp-aan-uit.png">
  <KnoppenBord />
</template>

<script>
import KnoppenBord from "@/components/KnoppenBord.vue";
export default {
  name: 'App',
  components: {
    KnoppenBord
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
img {
  width: 60%;
  height: auto;
}
</style>
