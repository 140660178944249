<template>
  <div class="knoppenbord-pagina">
    <div class="knoppenbord-titel">Knoppen</div>
    <div class="knoppenbord-knoppen">
      <div class="knop-container">
        <div class="knop-indicator"></div>
        <button v-show="status1 === 0" class="knop-donker" @click="setSwitchOn(1)">1</button>
        <button v-show="status1 === 1" class="knop-licht" @click="setSwitchOff(1)">1</button>
      </div>
      <div class="knop-container">
        <div class="knop-indicator"></div>
        <button v-show="status2 === 0" class="knop-donker" @click="setSwitchOn(2)">2</button>
        <button v-show="status2 === 1" class="knop-licht" @click="setSwitchOff(2)">2</button>
      </div>
      <div class="knop-container">
        <div class="knop-indicator"></div>
        <button v-show="status3 === 0" class="knop-donker" @click="setSwitchOn(3)">3</button>
        <button v-show="status3 === 1" class="knop-licht" @click="setSwitchOff(3)">3</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "KnoppenBord",
  data() {
    return {
      status1: 0,
      status2: 0,
      status3: 0
    }
  },
  methods: {
    setSwitchOn(idKnop) {
      // axios.post(`http://192.168.1.157:3000/device-on/${idKnop}`)
      axios.post(`https://switcher-api.akiosk.nl/device-on/${idKnop}`)
          .then((setResult) => {
            if(idKnop === 1) {
              this.status1 = parseInt(setResult.data.status);
            }
            if(idKnop === 2) {
              this.status2 = parseInt(setResult.data.status);
            }
            if(idKnop === 3) {
              this.status3 = parseInt(setResult.data.status);
            }
            // console.log(this.status1);
          })
          .catch((setError) => {
            console.log(setError);
          });
    },
    setSwitchOff(idKnop) {
      // axios.post(`http://192.168.1.157:3000/device-off/${idKnop}`)
      axios.post(`https://switcher-api.akiosk.nl/device-off/${idKnop}`)
          .then((setResult) => {
            if(idKnop === 1) {
              this.status1 = parseInt(setResult.data.status);
            }
            if(idKnop === 2) {
              this.status2 = parseInt(setResult.data.status);
            }
            if(idKnop === 3) {
              this.status3 = parseInt(setResult.data.status);
            }
            // console.log(this.status1);
          })
          .catch((setError) => {
            console.log(setError);
          });
    }
  },
  created() {
    // axios.get('http://192.168.1.157:3000/status/1')
    axios.get('https://switcher-api.akiosk.nl/status/1')
        .then((statusResult) => {
          this.status1 = statusResult.data;
        })
        .catch((statusError) => {
          console.log('error status 1: ' + statusError);
        });
    axios.get('https://switcher-api.akiosk.nl/status/2')
        .then((statusResult) => {
          this.status2 = statusResult.data;
        })
        .catch((statusError) => {
          console.log('error status 2: ' + statusError);
        });
    axios.get('https://switcher-api.akiosk.nl/status/3')
        .then((statusResult) => {
          this.status3 = statusResult.data;
        })
        .catch((statusError) => {
          console.log('error status 3: ' + statusError);
        });
  }
}
</script>

<style scoped>
.knoppenbord-titel {
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 50px;
}
.knoppenbord-knoppen {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
}
.knop-container {
  margin: 10px;
}
.knop-donker {
  font-size: 16px;
  font-weight: bold;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  color: white;
  background-color: black;
}
.knop-licht {
  font-size: 16px;
  font-weight: bold;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  color: black;
  background-color: greenyellow;
}
</style>